import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

interface BannerProps {
  data: any;
  // data: React.ReactNode[];
}

const Banner: React.FC<BannerProps> = ({ data = {} }) => {
  return (
    <>
      <section className={styles['banner-section']}>
        <div className={styles['banner-inner']}>
          <div className="container-1600 p-0">
            <div className={styles['banner-content']}>
              <div className={styles['banner-left']}>
                <div className={styles['banner-slider']}>
                  <div className="bannerswiperslider">
                    <Swiper
                      navigation={true}
                      loop={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {data?.items?.map((banner: any, index: number) => (
                        <SwiperSlide key={index}>
                          <Link
                            href={banner.href}
                            // aria-label={banner.ariaLabel}
                            /* data-slick-index={index === 0 ? -1 : index}
                        aria-hidden={index === 0 ? true : false} */
                          >
                            <Image
                              quality={75}
                              loading="eager"
                              priority
                              height={586}
                              width={960}
                              alt={banner.altText}
                              src={banner.imgSrc}
                            />
                          </Link>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
              <div className={styles['banner-right']}>
                <div className="wdRow wdgy-2">
                  <div className="wdCol-lg-12 wdCol-md-12 wdCol-sm-6">
                    <div className={styles['banner-img']}>
                      <Link href="/deal-of-the-day">
                        <Image
                          src="/images/home-new1/new-launch.jpg?v=1751"
                          quality={75}
                          height={312}
                          width={624}
                          alt="buy wooden furniture in India"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="wdCol-lg-12 wdCol-md-12 wdCol-sm-6">
                    <div className={styles['banner-img']}>
                      <Link href="/sofa-cum-beds">
                        <Image
                          src="/images/home-new1/Sofa-Cum-Beds-Gif.gif"
                          height={312}
                          width={624}
                          unoptimized
                          alt="wooden home furniture online"
                          priority
                          loading="eager"
                        />
                        {/* <div
                          style={{
                            display: 'inline-block',
                            height: '290px',
                            width: 'auto',
                            position: 'relative',
                            overflow: 'hidden',
                          }}
                        >
                          <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            // height={290}
                            // width={580}
                          >
                            <source
                              src="/images/home-new1/Sofa-Cum-Beds-Gif.mp4"
                              type="video/mp4"
                            />
                          </video>
                        </div> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.discountsection}>
        <div className={styles.discountinner}>
          <div className="container-1600 px-1600-0">
            <div className={styles.discountimg}>
              <Image
                src="/images/home-new1/emi-banner.jpg?v=17511"
                alt="Emi Installation"
                width={'1600'}
                height={'142'}
                quality={75}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
